import Slideout from 'slideout';
import 'slick-carousel';
export default {
  init() {
    $(document).ready(function () {
      var slideout = new Slideout({
        panel: document.getElementById('panel'),
        menu: document.getElementById('menu'),
        padding: 256,
        tolerance: 70,
      });
      document
        .querySelector('.hamburger-icon')
        .addEventListener('click', function () {
          slideout.toggle();
        });
      document.querySelector('.close').addEventListener('click', function () {
        slideout.toggle();
      });
      $('.mobile_menu .nav-drop').slideUp();
      $('.mobile_menu .has-child').click(function (e) {
        e.preventDefault();

        if ($(this).hasClass('is-active')) {
        } else {
          $('.mobile_menu .has-child').removeClass('is-active');
          $(this).addClass('is-active');
          $('.mobile_menu .nav-drop').slideUp();
          var child = $(this).next('.nav-drop');
          console.log(child);
          $(child).slideDown();
        }
      });
      $('#cases_form').submit(function () {
        console.log('Submitted');
        if ($('#athlete_name').val() == '') {
          $('#athlete_name').prop('disabled', true);
        }
        if ($('#athlete_sport').val() == '') {
          $('#athlete_sport').prop('disabled', true);
        }
        if ($('#drug_found').val() == '') {
          $('#drug_found').prop('disabled', true);
        }
      });

      $('.clear_button').click(function (e) {
        e.preventDefault();
        $('#cases_form').trigger('reset');
        $('#cases_form').trigger('submit');
      });

      function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null
          ? ''
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
      }

      $('#athlete_name').val(getUrlParameter('athlete_name'));
      $('#athlete_sport').val(getUrlParameter('athlete_sport'));
      $('#drug_found').val(getUrlParameter('drug_found'));

      $('.drop_button').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('is-active');
        var target = $(this).data('id');
        $('#' + target).toggle();
      });

      $('.accordion_section .accordion__title').click(function () {
        var content = $(this).next('.accordion__content');
        $(content).slideToggle();
        $(this).toggleClass('is-active');
      });

      $('.steps__title').on('click', function () {
        var target = $(this).data('for');
        $('.steps__content').hide();
        $('#' + target).show();
        $('.steps__title').removeClass('is_active');
        $(this).addClass('is_active');
      });

      $('.searchicon').click(function () {
        $('.searchform input').fadeToggle();
        console.log('Search Clicked');
      });

      $('.partner-slider').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 300,
        infinite: true,
        arrows: true,
        nextArrow:
          '<button type="button" role="button" aria-label="Next" style="color:#333333; font-size:20px;" class="slick-next default"><i class="far fa-chevron-right"></i></button>',
        prevArrow:
          '<button type="button" role="button" aria-label="Previous" style="color:#333333; font-size:20px;" class="slick-prev default"><i class="far fa-chevron-left"></i></button>',
        slidesToScroll: 1,
        slidesToShow: 5,
        swipe: true,
        draggable: true,
        touchMove: true,
        pauseOnHover: true,
        pauseOnFocus: false,
        responsive: [
          {
            breakpoint: 1026,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
        pauseOnDotsHover: true,
      });
    });
    function initMap($el) {
      // Find marker elements within map.
      var $markers = $el.find('.marker');

      // Create gerenic map.
      var mapArgs = {
        zoom: $el.data('zoom') || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      var map = new google.maps.Map($el[0], mapArgs);

      // Add markers.
      map.markers = [];
      $markers.each(function () {
        initMarker($(this), map);
      });

      // Center map based on markers.
      centerMap(map);

      // Return map instance.
      return map;
    }
    function initMarker($marker, map) {
      // Get position from marker.
      var lat = $marker.data('lat');
      var lng = $marker.data('lng');
      var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };

      // Create marker instance.
      var marker = new google.maps.Marker({
        position: latLng,
        map: map,
      });

      // Append to reference for later use.
      map.markers.push(marker);

      // If marker contains HTML, add it to an infoWindow.
      if ($marker.html()) {
        // Create info window.
        var infowindow = new google.maps.InfoWindow({
          content: $marker.html(),
        });

        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function () {
          infowindow.open(map, marker);
        });
      }
    }

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap(map) {
      // Create map boundaries from all map markers.
      var bounds = new google.maps.LatLngBounds();
      map.markers.forEach(function (marker) {
        bounds.extend({
          lat: marker.position.lat(),
          lng: marker.position.lng(),
        });
      });

      // Case: Single marker.
      if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());

        // Case: Multiple markers.
      } else {
        map.fitBounds(bounds);
      }
    }

    // Render maps on page load.
    $(document).ready(function () {
      $('.acf-map').each(function () {
        var map = initMap($(this));
      });
    });
  },
  finalize() {},
};
